.full-onbottom-bottom {
  // h3 {
  //   width:100%;

  //   @include breakpoint(xlarge) {
  //     width: 66%;
  //   }
  // }

  @include breakpoint(large) {
    .offset-50 {
      transform:translateY(50%);
      @include xy-fullgutters($grid-margin-gutters,margin,bottom,true);
   }
  }

  img {
    @include breakpoint(small only) {
      aspect-ratio: 4 / 3;
      object-fit: cover;
    }
  }
}

.\31 2-onbottom-bottom {
  & > .grid-x:first-child, .img-offset {
    padding-left:0 !important;
  }
}

.\31 0-onbottom-bottom {
  &:first-child .grid-x:first-child,
  &:first-child .grid-x:first-child .cell:first-child {
    @include breakpoint(medium down) {
      padding-left:0;
      padding-right:0;
    }
  }

  &:first-child > .grid-x:first-child > .cell:last-child {
    @include breakpoint(medium down) {
      @include xy-gutters($grid-padding-gutters,margin,left right,false)
    }
  }

  @include breakpoint(large) {
    .top-layer {
      position: absolute;
      top:0;
      left:0;
      z-index: 1;
      @include xy-fullgutters($grid-padding-gutters,margin,left right,false);
      @include xy-fullgutters($grid-vertical-gutters,padding,top bottom,false);

      .cell:last-child { margin-top:auto }
    }
  }
  @include breakpoint(large) {
    h3 {
      margin: 0;
      transform: translateY(-60%);
      padding-bottom:0;
    }
  }

  // @include breakpoint(xlarge) {
  //   h3 {
  //     transform: translateY(-75%);
  //   }
  // }

  @include breakpoint(xxlarge) {
    h3 {
      transform: translateY(-100%);
    }
  }
}

.full-onbottom-bottom:not(:first-child) .bg-video img,
.full-onbottom-bottom:not(:first-child) .bg-video video {
  max-height: 1200px;
}