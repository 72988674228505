#menuLogo {
  width: 25vw;
  max-width: 300px;
  min-width: 150px;
  height: auto;
  padding-top: 0.35%;
  fill: $white;
}

#titlebarWrapper.sticky-container {
  position: fixed;
  width: 100%;
  z-index: 100;
}

.title-bar {
  @include xy-fullgutters($grid-margin-gutters, padding, left right, false);
  @include xy-smartgutters($grid-vertical-gutters, padding, top, false, 0.7);
  @include xy-smartgutters($grid-vertical-gutters, padding, bottom, false, 0.5);

  @include breakpoint(medium) {
    @include xy-smartgutters(
      $grid-vertical-gutters,
      padding,
      top bottom,
      false,
      0.4
    );
  }
}

.title-bar-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    180deg,
    rgba(0, 6, 15, 0.9) 13.33%,
    rgba(0, 13, 34, 0.25) 100%
  );
  z-index: -1;
}

#home .title-bar-bg {
  opacity: 0;
}

.off-canvas.position-right {
  @include breakpoint(medium) {
    width: 580px;
  }
}

section:first-child .add-topbarspace {
  @include breakpoint(large) {
    transform: translateY(100px);
  }
  @include breakpoint(xxlarge) {
    transform: translateY(120px);
  }
}

.add-topbarpadding {
  padding-top: 80px;

  @include breakpoint(medium) {
    padding-top: 120px;
  }
  @include breakpoint(xlarge) {
    padding-top: 200px;
  }
}

footer {
  position: relative;
  overflow-x: hidden;
  .menu.horizontal {
    @include xy-gutters($grid-margin-gutters, margin, left right, true);
  }
  .menu.horizontal li {
    @include xy-gutters($grid-margin-gutters, margin, left right, false);
  }

  .menu.vertical .button.small {
    text-transform: none;
  }

  .has-footerIcon {
    position: relative;
    overflow: clip;
  }

  .menu.vertical.compact {
    @include breakpoint(small only) {
      margin-bottom: 1rem;
    }
    @include breakpoint(medium down) {
      margin-top: 0.5rem;
    }
  }

  .address {
    @include breakpoint(small only) {
      margin-top: 1.5rem;
    }

    & > p:nth-child(2) {
      @include breakpoint(medium only) {
        margin-top: 0.5rem;
      }
    }
  }
}

#gmbhIcon {
  max-width: 60px;

  path {
    fill: $white;
    transition: fill 0.25s linear;
  }
}

#footerIcon,
#navIcon {
  position: absolute;
  right: 0;
  bottom: 25%;
  width: 85%;
  height: auto;
  fill: rgba($logo-blue, 0.05);
}

#footerIcon {
  pointer-events: none;
}

#navIcon {
  bottom: 0%;
  width: 65%;
  fill: rgba($white, 0.05);
  z-index: 1;
  pointer-events: none;
}

.menu.vertical.compact {
  a.button {
    padding: 0.25rem 0;
    line-height: 1.1;
  }
}

.off-canvas {
  overflow-x: hidden;
}

// #mainMenu div {
//   height: 100vh;
// }

#mainNav {
  display: flex;
  align-items: center;
  max-height: 100vh;
  overflow: hidden;

  ul {
    width: 100%;
  }

  li.pb-grid {
    border-bottom: 1px solid #7a90af;
    @include xy-fullgutters($grid-vertical-gutters, margin, bottom, false);
  }

  .tone-down a.button:not(:hover) {
    color: #a0b6d5;

    #gmbhIcon path {
      fill: #a0b6d5;
    }
  }

  .overline {
    padding-bottom: 0 !important;
  }

  a.button,
  .is-accordion-submenu a.button {
    border-width: 0;
    align-items: flex-start;
    padding-top:10px;
    padding-bottom:10px;

    &.headquater {
      align-items: center;

      span:last-child {
        transform: translate(0, 15%);
      }
    }

    &:hover,
    &:focus:hover {
      color: $accent-blue;
    }

    &:hover #gmbhIcon path {
      fill: $accent-blue;
    }

    &:focus {
      color: $white;
    }

    @include breakpoint(small only) {
      padding-top: 8px;
      padding-bottom: 8px;
    }

    @include breakpoint(xxlarge) {
      @include xy-smartgutters(
        $grid-vertical-gutters,
        padding,
        top bottom,
        false,
        0.25
      );
    }

    span {
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }
    }

    & > span:not(.overline) {
      @include breakpoint(small only) {
        font-size: 60%;
      }
      @include breakpoint(medium only) {
        font-size: 80%;
      }
    }
  }

  .button .icon {
    width: 20px;
    transition: all 0.4s ease;
    transform-origin: center center;
    @include breakpoint(small only) {
      margin-top: -5px;
    }
  }

  .button:hover .icon svg path {
    stroke: $accent-blue;
  }
  .is-accordion-submenu-parent[aria-expanded="true"] .button {
    color: $accent-blue;
  }
  .is-accordion-submenu-parent[aria-expanded="true"] .button .icon {
    transform: rotate(180deg);
  }

  ul.nested {
    width: 100%;
    margin: 5px 0;

    li:last-child {
      @include xy-gutters($grid-vertical-gutters, margin, bottom, false);
    }

    a.button:not(:hover) {
      color: #a0b6d5;
    }
  }
}

#sideNav {
  display: flex;
  align-items: center;
  a.button {
    @include breakpoint(large) {
      @include xy-gutters($grid-vertical-gutters, padding, top bottom, false);
    }
  }

  ul {
    width: 100%;
  }
}
