@charset 'utf-8';

@import "global/vars";
@import "settings";
@import "foundation";

$contrast-warnings: false;

// Global styles
@include foundation-global-styles;
@include foundation-forms;
@include foundation-typography;

// Grids (choose one)
@include foundation-xy-grid-classes;
// @include foundation-grid;
// @include foundation-flex-grid;

// Generic components
@include foundation-button;
// @include foundation-button-group;
@include foundation-close-button;
@include foundation-label;
// @include foundation-progress-bar;
// @include foundation-slider;
// @include foundation-range-input;
@include foundation-switch;
@include foundation-table;
// Basic components
// @include foundation-badge;
// @include foundation-breadcrumbs;
// @include foundation-callout;
// @include foundation-card;
@include foundation-dropdown;
// @include foundation-pagination;
@include foundation-tooltip;

// Containers
@include foundation-accordion;
// @include foundation-media-object;
// @include foundation-orbit;
// @include foundation-responsive-embed;
@include foundation-tabs;
// @include foundation-thumbnail;
// Menu-based containers
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-accordion-menu;
// @include foundation-drilldown-menu;
// @include foundation-dropdown-menu;

// Layout components
@include foundation-off-canvas;
@include foundation-reveal;
@include foundation-sticky;
@include foundation-title-bar;
// @include foundation-top-bar;

// Helpers
// @include foundation-float-classes;
@include foundation-flex-classes;
@include foundation-visibility-classes;
// @include foundation-prototype-classes;

@import "global/mixins";
@import "global/colors";
@import "global/typography";
@import "global/grid";

// @import "components/swiper";
@import "components/ui";
@import "components/navigation";
@import "components/media";
@import "components/lists";
@import "components/accordions";
@import "components/textimage";
@import "components/pages";
@import "components/companygrid";

:root {
  // --bb-gradient: linear-gradient(45deg, rgb(255,255,255) -15%,rgb(18, 113, 255) -14%, rgb(18, 113, 255) -10%, rgb(255,255,255) -5%,rgb(0, 25, 67) 0);
  @include breakpoint(small only) {
    --x-spacing: #{map-get($grid-margin-gutters, "small")};
    --y-spacing: #{map-get($grid-margin-gutters, "small")};
  }
  @include breakpoint(medium) {
    --x-spacing: #{map-get($grid-margin-gutters, "medium")};
    --y-spacing: #{map-get($grid-margin-gutters, "medium")};
  }
  @include breakpoint(large) {
    --x-spacing: #{map-get($grid-margin-gutters, "large")};
    --y-spacing: #{map-get($grid-margin-gutters, "large")};
  }
  @include breakpoint(xlarge) {
    --x-spacing: #{map-get($grid-margin-gutters, "xlarge")};
    --y-spacing: #{map-get($grid-margin-gutters, "xlarge")};
  }
}
