// article {
//   @include breakpoint(1920) {
//     padding-left:5%;
//     padding-right:5%;
//   }
// }

section,
.relative,
footer {
  position: relative;
}
section,
footer {
  z-index: 6;
  overflow-x: hidden;
  overflow-y: clip;
}

.absolute {
  position: absolute;
  top: 0;
  left: 0;
}
.relative {
  position: relative;
}

// MARGINS /////////////////////////////

// ALL
.m {
  &-0 {
    margin: 0 !important;
  }
}
// TOP
.mt {
  &-0 {
    margin-top: 0;
  }
  &-auto {
    margin-top: auto;
  }
  &-grid {
    @include xy-smartgutters($grid-vertical-gutters, margin, top, false, 1);
  }
}
//BOTTOM
.mb {
  &-0 {
    margin-bottom: 0;
  }
  &-auto {
    margin-bottom: auto;
  }
  &-grid {
    @include xy-smartgutters($grid-vertical-gutters, margin, bottom, false, 1);
  }
  &-l-0 {
    @include breakpoint(large) {
      margin-bottom: 0;
    }
  }
  &-xl-0 {
    @include breakpoint(xlarge) {
      margin-bottom: 0;
    }
  }
}
// LEFT
.ml-auto {
  margin-left: auto;
}
// RIGHT
.mr-auto {
  margin-right: auto;
}

// PADDINGS /////////////////////////////

// ALL
.p {
  &-0 {
    padding: 0 !important;
  }
}

// VERTICAL
.py {
  &-grid {
    @include xy-smartgutters(
      $grid-vertical-gutters,
      padding,
      top bottom,
      false,
      1
    );
  }
  &-grid--spaced {
    @include xy-smartgutters(
      $spaced-vertical-gutters,
      padding,
      top bottom,
      false,
      1
    );
  }

  &-250 {
    @include xy-smartgutters(
      $grid-vertical-gutters,
      padding,
      top bottom,
      false,
      4
    );
  }

  &-l-0 {
    @include breakpoint(large) {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}
.px {
  &-grid {
    @include xy-smartgutters(
      $grid-vertical-gutters,
      padding,
      left right,
      false,
      1
    );
  }
  &-grid--spaced {
    @include xy-smartgutters(
      $spaced-vertical-gutters,
      padding,
      left right,
      false,
      1
    );
  }
}
// TOP
.pt {
  &-0 {
    padding-top: 0 !important;
  }
  &-grid {
    @include xy-smartgutters($grid-vertical-gutters, padding, top, false, 1);
  }
  &-grid--spaced {
    @include xy-smartgutters($spaced-vertical-gutters, padding, top, false, 1);
  }
  &-grid--first {
    @include xy-smartgutters($spaced-vertical-gutters, padding, top, false, 2);
  }
  &-100 {
    @include xy-smartgutters($grid-vertical-gutters, padding, top, false, 2);
  }
  &-150 {
    @include xy-smartgutters($grid-vertical-gutters, padding, top, false, 3);
  }
  &-200 {
    @include xy-smartgutters($grid-vertical-gutters, padding, top, false, 4);
  }
  &-250 {
    @include xy-smartgutters($grid-vertical-gutters, padding, top, false, 4);
  }
}
// BOTTOM
.pb {
  &-0 {
    padding-bottom: 0 !important;
  }
  &-grid {
    @include xy-smartgutters($grid-vertical-gutters, padding, bottom, false, 1);
  }
  &-grid--spaced {
    @include xy-smartgutters(
      $spaced-vertical-gutters,
      padding,
      bottom,
      false,
      1
    );
  }
  &-100 {
    @include xy-smartgutters($grid-vertical-gutters, padding, bottom, false, 2);
  }
  &-150 {
    @include xy-smartgutters($grid-vertical-gutters, padding, bottom, false, 3);
  }
  &-200 {
    @include xy-smartgutters($grid-vertical-gutters, padding, bottom, false, 4);
  }
  &-250 {
    @include xy-smartgutters($grid-vertical-gutters, padding, bottom, false, 4);
  }

  &-l-0 {
    @include breakpoint(large) {
      padding-bottom: 0;
    }
  }
  &-xl-0 {
    @include breakpoint(xlarge) {
      padding-bottom: 0;
    }
  }
  &-xxl-0 {
    @include breakpoint(xxlarge) {
      padding-bottom: 0;
    }
  }
}

//
.pr {
  &-grid {
    @include xy-smartgutters($grid-margin-gutters, padding, right, false, 1);
  }
}

.overline {
  @include xy-smartgutters($grid-vertical-gutters, padding, bottom, false, 1);
}

// smallstack is 15 25 50

.no-border,
.no-border .grid-x {
  border-width: 0 !important;
}

.xlarge-abs-bento {
  @include breakpoint(xlarge) {
    position: absolute;
    top: 0;
    left: 0;
    @include xy-fullgutters($grid-margin-gutters, margin, left, false);
  }
}

.w-full {
  width: 100%;
}

.w-50 {
  width: 50%;
}
.w-66 {
  width: 66%;
}

.h-full {
  height: 100%;
}

.xlarge-mw20 {
  @include breakpoint(large) {
    max-width: 40vw;
  }
  @include breakpoint(xlarge) {
    max-width: 20vw;
  }
}

.xxxxlarge-up-3 > .cell {
  @include breakpoint(2300px) {
    width: 33.3333%;
  }
}

ul.grid-x {
  list-style-type: none;
}

#iconGrid {
  .align-center-middle {
    height: 100%;
    border-top: 1px solid $logo-blue;
    border-bottom: 1px solid $logo-blue;

    @include xy-smartgutters(
      $grid-vertical-gutters,
      padding,
      left right,
      false,
      2
    );
    @include xy-smartgutters(
      $grid-vertical-gutters,
      padding,
      top bottom,
      false,
      1
    );

    @include breakpoint(large) {
      border-top: 2px solid $logo-blue;
      border-bottom: 2px solid $logo-blue;
      @include xy-smartgutters(
        $grid-vertical-gutters,
        padding,
        top bottom,
        false,
        1.5
      );
      @include xy-smartgutters(
        $grid-vertical-gutters,
        padding,
        left right,
        false,
        1.5
      );
    }
    @include breakpoint(xlarge) {
      @include xy-smartgutters(
        $grid-vertical-gutters,
        padding,
        top bottom,
        false,
        1
      );
      @include xy-smartgutters(
        $grid-vertical-gutters,
        padding,
        left right,
        false,
        1.5
      );
    }
  }

  .cell:first-child .align-center-middle,
  .cell:last-child .align-center-middle {
    border-left: 1px solid $logo-blue;
    border-right: 1px solid $logo-blue;

    @include breakpoint(large) {
      border-width: 2px;
    }
  }

  .text-white & {
    .align-center-middle {
      border-color: $white !important;
    }
    svg {
      fill: $white;
      width: 100%;
    }
  }
}

.show-for-xxlarge {
  @include breakpoint(xlarge down) {
    display: none;
  }
}

.hide-for-xxlarge {
  @include breakpoint(xxlarge) {
    display: none;
  }
}
