.aspect-ratio-2-1 {
  aspect-ratio: 15/7;
  object-fit: cover;
  object-position: center;
}

.small-aspect-ratio-4-3 {
  @include breakpoint(small only) {
    aspect-ratio: 4/3 !important;
    object-fit: cover;
    object-position: center;
  }
}

.aspect-ratio-16-9 {
  aspect-ratio: 16/9;
  max-height: none !important;
}

.aspect-ratio-a4 {
  aspect-ratio: 1/1.414;
  object-fit: cover;
  object-position: center;
}

.opacity-1 {
  opacity: 1 !important;
}

.object-top {
  object-position: top center;
}

.max-h-screen {
  max-height: 100vh;
  object-fit: cover;
  object-position: center;
}
.max-h-screen--80 {
  max-height: 80vh;
  object-fit: contain;
  object-position: center;
}
.max-w-screen--80 {
  max-width: 80vw;
  object-fit: contain;
  object-position: center;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

// VIDEOS

#heroFrame {
  overflow: hidden;
  z-index: 4;

  @include breakpoint(large) {
    color: $white;
  }
  .bg-video video {
    @include breakpoint(medium down) {
      opacity: 0.6;
      height: auto;
      aspect-ratio: 4/7;
    }
  }
}

.hero-content {
  --bg-opacity: 0.8;
  @include breakpoint(medium down) {
    position: relative;
    color: $white;
    justify-content: flex-end;

    &:before {
      content: " ";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: black;
      opacity: var(--bg-opacity);
      z-index: 1;
    }
  }
  @include breakpoint(large) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    align-items: end;
    z-index: 1;
  }

  @media screen and #{breakpoint(large)} and #{breakpoint(landscape)} {
    height:100vh;
  }

  & > .cell {
    position: relative;
    z-index: 2;
  }
}

.bg-video video,
.bg-video img {
  width: 100%;
  height: 100%;
  opacity: 0.5;
  object-fit: cover;
  object-position: center;
}

.large-fullscreen {
  width: 100%;
  aspect-ratio: 16/9;
  @include breakpoint(large) {
    height: 100vh;
    aspect-ratio: unset;
    overflow: hidden;
  }

  .bg-video {
    height: 100%;
  }
}

.screen {
  mix-blend-mode: screen;
}

.bg-img {
  background: $black-navy;

  img {
    opacity: 0.75;
  }
}

.like-overlay {
  opacity: 0.75;
}

.vid-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: $black-navy-50;
}

#imageVideoReveal {
  padding: 0;
  background: black;
  // display: flex;
  justify-content: center;
  align-items: center;

  &[aria-hidden="false"] {
    display: flex !important;
  }

  iframe {
    background-image: url(../img/Weissbach_Metallbau_Schweissen_01.webp);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.qr-code {
  aspect-ratio: 1/1;
  transform: translateX(-8%);
  mix-blend-mode: multiply;

  @include breakpoint(xlarge) {
    max-width: 130px;
  }
}

// aspect ratios
.sm-square img {
  @include breakpoint(small only) {
    aspect-ratio: 1/1;
    object-fit: cover;
  }
}

.mddown-pass img,
.mddown-pass video {
  @include breakpoint(medium down) {
    aspect-ratio: 4/3;
    object-fit: cover;
  }
}
