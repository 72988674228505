#contact section,
#downloads section {
  p.overline:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    border-top: 1px solid $navy;
    @include xy-fullgutters($grid-margin-gutters, margin, left right, false);
  }
}

#news,
#jobs {
  a.accordion-title.grid-x {
    display: flex;
  }
  // a.grid-x, .accordion-content.grid-x {
  //   @include xy-gutters($grid-margin-gutters,marign,right left,true);
  // }
  .accordion-title > * {
    display: block;
  }

  li.accordion-item {
    border-top-width: 1px;
    border-top-style: solid;
    border-color: $logo-blue;
  }
}

.intro svg {
  width: 100%;
  height: auto;
  max-width: 550px;
}

#history {
  position: relative;

  section.bg-bluegray .textcontent {
    background-color: #e4e6ef;
  }

  .section-line {
    position: absolute;
    z-index: 10;
    left: 0;
    top: 0;
    width: 2px;
    height: 100%;
    background-color: $accent-blue;
    @include xy-smartgutters($grid-padding-gutters, margin, left, false, 1.5);

    @include breakpoint(large) {
      left: 50%;
      margin-left: -1px;
    }
  }

  #historySections section.timelineelement:first-of-type .section-line {
    @include xy-smartgutters($spaced-vertical-gutters, margin, top, false, 1);
  }

  #historySections section.textimage .section-line {
    @include xy-smartgutters($grid-padding-gutters, margin, left, false, 1);
    @include breakpoint(large) {
      left: 50%;
      margin-left: -1px;
    }
  }

  section .timestamp {
    display: block;
    padding-bottom: 0;
    text-align: center;
    font-family: "Roboto Mono", monospace;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    position: relative;
    z-index: 11;

    @include breakpoint(medium down) {
      display: inline-block;
      text-align: left;
      padding: 2px 5px;
      transform: scale(1) !important;
    }

    .bluebar {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      background-color: $accent-blue;
      z-index: -1;

      @include breakpoint(large) {
        border: 2px solid $white;
      }
    }

    .line {
      position: relative;
    }
  }

  section .grid-x {
    @include breakpoint(medium down) {
      border-width: 0 !important;
    }
  }

  section > .cell:first-child:not(.sticky) {
    @include breakpoint(large) {
      position: relative;
    }
  }

  section.textimage .bluebar {
    border-width: 0 !important;
  }

  section.textimage .cell.medium-2 .timestamp {
    margin-bottom: 0 !important;
  }

  .textcontent .cell:first-child {
    @include xy-smartgutters($grid-margin-gutters, padding, right, false, 1);
  }
  .textcontent p:last-child {
    margin-bottom: 0;
  }

  .has-tip {
    @include xy-smartgutters($grid-padding-gutters, padding, right, false, 1.5);
    display: block;
  }

  .has-tip .icon {
    position: absolute;
    top: 0.55em;
    right: 0;
  }

  .sticky .overline {
    font-size: 13px;
  }

  .inlineimage {
    @include xy-smartgutters(
      $grid-vertical-gutters,
      margin,
      top bottom,
      false,
      0.5
    );
    display: block;
    max-width: 600px;

    @include breakpoint(large) {
      width: 25vw;
      margin-bottom: 0;
    }

    @include breakpoint(medium down) {
      img {
        object-fit: contain;
        object-position: left;
        width: auto !important;
        max-height: 500px;
      }
    }
  }

  .bg-bluegray .inlineimage img {
    border: 5px solid $black-navy;
  }

  .switch {
    .switch-paddle {
      background: #89a5fa;
    }
    input:checked ~ .switch-paddle {
      background: $navy;
    }
    label,
    label:after {
      border-radius: 25px !important;
    }
  }
}
