.listing {
  margin: 0;
  list-style: none;

  li {
    border-top-width: 1px;
    border-top-style: solid;
    @include xy-fullgutters($grid-vertical-gutters, padding, top bottom, false);

    @include breakpoint(large) {
      @include xy-gutters($spaced-vertical-gutters, padding, top bottom, false);
    }
  }

  li ul {
    @include xy-gutters($grid-vertical-gutters, margin, top bottom, false);

    li {
      border-width: 0 !important;
      padding: 0 !important;
    }
  }

  // .cell &:not(.ungrid) {
  //   @include xy-gutters($grid-margin-gutters,margin,right left,false);
  // }

  .cell & li {
    .cell:first-child {
      padding-left: 0;
    }
    .cell:last-child {
      padding-right: 0;
    }
  }

  p:last-child {
    margin-bottom: 0;
  }
}

.listing.ungrid {
  padding: 0;
  // @include xy-gutters($grid-margin-gutters,margin,left right,true);
}

.listing a {
  color: $logo-blue;

  .text-white & {
    color: $white;
  }
}

.grid-list {
  li {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    border-top-width: 1px;
    border-top-style: solid;
    @include xy-gutters($spaced-vertical-gutters, padding, top bottom, false);
  }

  .overline {
    padding-bottom: 0 !important;
  }
}

.\31 0-anchors-h4 {
  h2 {
    @include breakpoint(xlarge) {
      width: 80%;
    }
    @include breakpoint(xxxlarge) {
      width: 66%;
    }
  }
  h3 {
    margin: 0;
    font-weight: 500;
  }
  li {
    // padding: rem-calc(20) 0;
    .overline {
      transform: translateY(10%);
    }
  }
}

.listing li span.h1 {
  margin-bottom: -0.1em;
}

.\31 0-56-h3,
.full-46-h3 {
  li {
    @include xy-fullgutters(
      $spaced-vertical-gutters,
      padding,
      top bottom,
      false
    );
    @include breakpoint(large) {
      @include xy-fullgutters(
        $grid-vertical-gutters,
        padding,
        top bottom,
        false
      );
    }
  }
  h3 {
    @include breakpoint(small only) {
      @include xy-smartgutters(
        $grid-vertical-gutters,
        margin,
        bottom,
        false,
        0.8
      );
    }
    @include breakpoint(medium only) {
      @include xy-smartgutters(
        $grid-vertical-gutters,
        margin,
        bottom,
        false,
        0.6
      );
    }
  }
}

.\31 2-linked-h1 {
  li {
    @include xy-smartgutters(
      $grid-vertical-gutters,
      padding,
      top bottom,
      false,
      1
    );
    // border-top-width:2px;
  }
}

// #contact

.contact-card {
  // & > .cell:first-child {
  //   @include breakpoint(500px) {
  //     width:
  //   }
  // }

  .icon {
    display: block;
    width: 15px;
    height: 20px;
    margin-right: 5px;
    transform: translateY(8%);
    flex-shrink: 0;

    svg {
      width: 100%;
      height: 100%;
    }

    @include breakpoint(medium) {
      width: 20px;
      height: 25px;
      margin-right: 10px;
    }
  }

  strong {
    margin-bottom: 0.5em;
  }
  p:not(:last-child) {
    margin-bottom: 0.25em;
    text-indent: 0;
  }
  p:last-child {
    margin-bottom: 0;
  }
  p.overline {
    @include xy-smartgutters(
      $grid-vertical-gutters,
      padding,
      bottom,
      false,
      0.5
    );
  }

  p.grid-x {
    flex-flow: row;
  }

  a.liam {
    word-break: break-word;
  }
}
