// $logo-blue : #001943;
// $accent-blue: get-color(primary);
// $navy: #01112A;
// $black-navy :#00060F;

.text-white {
  color: $white;
  .listing li {
    border-top-color: $white;
  }
}
.text-logo {
  color: $logo-blue;
}
.text-accent {
  color: $accent-blue;
}

.bg-white {
  background-color: $white;
}
.bg-logo {
  background-color: $logo-blue;
}
.bg-navy {
  background-color: $navy;
}
.bg-accent {
  background-color: $accent-blue;

  .button {
    color: $white !important;
    border-color: $white !important;
    .icon path {
      stroke: $white !important;
    }
  }
}
.bg-navyblack {
  background-color: $black-navy;
}

.fill-logo {
  fill: $logo-blue;
}
.fill-white path {
  fill: $white;
}
