/*
min: 768px
max: 2880px
predefined values@ 1780px 
*/

body {
  font-variation-settings: "slnt" 0, "wdth" 125, "GRAD" 0, "XOPQ" 96, "XTRA" 468,
    "YOPQ" 79, "YTAS" 720, "YTDE" -203, "YTFI" 738, "YTLC" 530, "YTUC" 745;
}

/* H_/H1 95 */
h1,
.h1 {
  // 3rem @ 768px
  // 7.5rem @ 2880px
  font-style: normal;
  font-optical-sizing: auto;
  font-weight: 420;
  line-height: 1.1em;

  .small-scaledown & {
    @include breakpoint(small only) {
      font-size: rem-calc(26px);
    }
  }

  &.bigbutton-ani {
    @include breakpoint(small only) {
      font-size: 6.5vw;
    }
  }
}

/* H_/H1 110 */
h1.giant,
.h1.giant {
  line-height: 1.1em;
  padding-bottom: 0.05em;
  font-weight: 480;

  @include breakpoint(large) {
    font-size: unquote("clamp(3.5rem, 2.2273rem + 2.6515vw, 7.5rem)");
  }
  // @include breakpoint(xlarge) {
  //   font-size: unquote("clamp(2.25rem, 3.1rem + 3.8471vw, 8.5rem)");
  // }
  // @include breakpoint(xxlarge) {
  //   font-size: unquote("clamp(2.25rem, 3.5304rem + 3.8471vw, 9.6858rem)");
  // }
}

/* H_/H2 75 */
h2,
.h2 {
  // 1.9375rem @ 768px
  // 4rem @ 2880px
  font-style: normal;
  font-optical-sizing: auto;
  // font-weight: $global-weight-bold;
  line-height: 1.1em;
  font-weight: 405; // a safari thing

  .small-scaledown & {
    @include breakpoint(small only) {
      font-size: rem-calc(22px);
    }
  }
}

h3,
.h3 {
  // 1.9375rem @ 768px
  // 4rem @ 2880px
  font-style: normal;
  font-optical-sizing: auto;
  font-weight: 400;
  letter-spacing: 0.01em;
  line-height: 1.2em;

  &.uppercase {
    letter-spacing: 0.03em;
  }

  font-size: 110%;

  .small-scaledown & {
    @include breakpoint(small only) {
      font-size: rem-calc(18px);
    }
  }
}

h4,
.h4 {
  // 1.5625rem @ 768px
  // 2.5rem @ 2880px
  font-style: normal;
  font-optical-sizing: auto;
  font-weight: 500;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  line-height: 1.2;
}
@include breakpoint(medium) {
  h1,
  .h1 {
    font-size: rem-calc(48px);
  }
  h2,
  .h2 {
    font-size: rem-calc(40px);
  }
  h3,
  .h3 {
    font-size: rem-calc(31px);
  }
  h4,
  .h4 {
    font-size: rem-calc(25px);
  }
  h5,
  .h5 {
    font-size: rem-calc(20px);
  }
  h6,
  .h6 {
    font-size: rem-calc(16px);
  }
}

@include breakpoint(large) {
  h1,
  .h1 {
    font-size: unquote("clamp(3rem, 1.9091rem + 2vw, 5.2rem)");
  }
  h2,
  .h2 {
    font-size: unquote("clamp(2.5rem, 1.6818rem + 1.7045vw, 4.75rem)");
  }
  h3,
  .h3 {
    font-size: unquote("clamp(1.9375rem, 1.4602rem + 0.9943vw, 3.25rem)");
  }
  h4,
  .h4 {
    font-size: unquote("clamp(1.5625rem, 1.2216rem + 0.7102vw, 2.5rem)");
  }
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4 {
  margin-top: -0.12em;
  @include xy-fullgutters($spaced-vertical-gutters, margin, bottom, false);
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4 {
  &:not(.giant) {
    // font-family: "Space Grotesk", sans-serif;
    text-transform: uppercase;
    // font-weight: 400;
  }

  sup {
    top: -0.8em;
    font-size: 50%;
  }
}

p {
  font-style: normal;
  // font-family: "Space Grotesk", sans-serif;

  // font-weight: 337;
  font-weight: 330;
  font-size: unquote("clamp(1rem, 0.7727rem + 0.4735vw, 1.625rem)");
  line-height: 1.45;
  // font-size: rem-calc(24px);
  // line-height: rem-calc(32px);
  // letter-spacing: -0.03em;
  // @include breakpoint(medium) {
  //   text-indent: 25px;
  // }
  // @include breakpoint(xlarge) {
  //   text-indent: 50px;
  // }
}

p + .button {
  @include xy-fullgutters($grid-margin-gutters, margin, top, false);
}

// h1 + p, .h1 + p,
// h2 + p, .h2 + p  {
//   @include xy-fullgutters($grid-margin-gutters,margin,top,false);
// }

p.unindent,
p.unindent + p ~ p,
.unindent > p {
  text-indent: 0;
}

p > a {
  text-decoration: underline;
}

.plast-mb0 {
  p:last-child {
    margin-bottom: -1px;
  }
}

.smaller {
  font-size: rem-calc(16px);
  @include breakpoint(medium) {
    font-size: rem-calc(18px);
  }
  @include breakpoint(large) {
    font-size: rem-calc(20px);
  }
}

.overline {
  font-size: rem-calc(12px);
  font-weight: 350;
  // letter-spacing: -0.02em;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  text-indent: 0;
  opacity: 0.8;
  font-family: $body-font-family;

  margin-bottom: 0;
  @include breakpoint(medium) {
    font-size: rem-calc(14px);
    font-weight: 450;
  }
  @include breakpoint(large) {
    // font-weight: 250;
    font-size: rem-calc(16px);
    line-height: rem-calc(21px);
  }
  @include breakpoint(large only) {
    padding-bottom: 25px !important;
  }

  @include breakpoint(xxxlarge) {
    font-size: rem-calc(18px);
    line-height: rem-calc(24px);
  }
}

strong,
.strong {
  font-weight: 500;
}

.strongest {
  font-weight: 600;
}

.space {
  font-family: "Space Grotesk", sans-serif;
  font-optical-sizing: auto;
  // font-weight: <weight>;
  font-style: normal;
}

.text-button {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: 0.02em;
  // padding: rem-calc(10) 0;

  @include breakpoint(medium) {
    font-size: 1.125rem;
  }
  @include breakpoint(large) {
    font-size: rem-calc(22);
    // padding: rem-calc(20) 0;
  }
  @include breakpoint(xxlarge) {
    font-size: rem-calc(24);
    line-height: rem-calc(32);
    padding: rem-calc(15) 0;
  }
}

.uppercase {
  text-transform: uppercase;
}
