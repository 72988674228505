.button {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.2;
  letter-spacing: 0.01em;
  text-align: left;
  text-transform: uppercase;

  border-bottom-width: 1px;
  border-bottom-style: solid;

  position: relative;
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  flex-flow: row nowrap;
  padding: rem-calc(10) 0;

  @include breakpoint(medium) {
    font-size: 1.125rem;
  }
  @include breakpoint(large) {
    font-size: rem-calc(20);
    padding: rem-calc(15) 0;
  }
  @include breakpoint(xxlarge) {
    font-size: rem-calc(22);
    line-height: rem-calc(32);
    padding: rem-calc(15) 0;
  }

  &.small,
  &.little {
    font-weight: 400;
  }

  &.small {
    font-size: 1rem;
    line-height: 1.2;
    @include breakpoint(large) {
      font-size: 1.25rem;
    }
    @include breakpoint(xxlarge) {
      font-size: 1.5rem;
      line-height: rem-calc(32);
    }
  }

  &.little {
    font-size: rem-calc(15px);
    line-height: 1.2;
    @include breakpoint(large) {
      font-size: 1.25rem;
      // line-height: rem-calc(32);
    }
  }

  &.consent {
    position: absolute;
    flex-direction: column;
    background: rgba(0, 0, 0, 0.9);
    transition: all 0.4s ease;

    .icon {
      @include xy-smartgutters($grid-vertical-gutters, margin, top, false, 1);
      svg circle {
        transition: all 0.4s ease;
      }
    }

    &:hover {
      opacity: 0.9;
      .icon svg circle {
        fill: $accent-blue;
      }
    }

    .px-grid {
      text-transform: none;
      font-weight: normal;
      font-size: 14px;
      @include breakpoint(large) {
        font-size: 16px;
      }
    }
  }
}

a.download, .request-download {
  border: none;
  margin: 0;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  color: $logo-blue;

  img {
    // max-width: 120px;
    padding: 10px;
    border: 1px solid #a0b6d5;
  }

  .icon {
    font-size: 1rem;
    text-align: center;
    font-weight: 500;
    text-transform: uppercase;
    line-height: 1.5;
    display: block;
    stroke: $accent-blue;
    color: $accent-blue;
    width: 45px;
    height: 65px;
    @include xy-smartgutters(
      $grid-vertical-gutters,
      margin,
      bottom,
      false,
      0.5
    );
    margin-top: -5px;
    @include xy-smartgutters($grid-padding-gutters, margin, right, false, 1);
  }

  svg {
    height: auto !important;
  }

  .smaller {
    font-size: 14px;
  }

  @include breakpoint(small only) {
    @include xy-smartgutters($grid-vertical-gutters, margin, bottom, false, 1);
  }

  @include breakpoint(medium) {
    .icon {
      margin-top: 0;
      margin-right: 0;
      height: 65px;
      order: 2;
      margin-left: auto;
    }
    .description {
      order: 1;
      @include xy-smartgutters($grid-padding-gutters, padding, right, false, 1);
    }
    .smaller {
      font-size: 16px;
    }
  }
  @include breakpoint(900px) {
    .icon {
      order: 1;
      margin-left: 0;
      @include xy-smartgutters(
        $grid-padding-gutters,
        margin,
        right,
        false,
        1
      );
    }
    .description {
      order: 2;
    }
  }

  &.has-image {
    @include breakpoint(900px) {
      flex-flow: column;
      .icon {
        // order: 1;
        // margin-left: 0;
        margin-right: 0;
        @include xy-smartgutters(
          $grid-vertical-gutters,
          margin,
          bottom,
          false,
          0.75
        );
      }
      // .description {
      //   order: 2;
      // }
    }

    @include breakpoint(xlarge) {
      .icon {
        // order: 1;
        // margin-left: 0;
        @include xy-smartgutters(
          $grid-vertical-gutters,
          margin,
          bottom,
          false,
          0.5
        );
      }
    }
  }
}

.request-download, a.download {
  svg path {transition: transform 0.4s ease;}
  &:hover svg {
    #point {transform: translateY(5px);}
    #line {transform: scaleY(1.1);transform-origin: 0 0;}
  }
}

.icon svg {
  width: 100%;
  height: auto;
  display: block;
}

.button.text-accent,
.button.active {
  color: $accent-blue;
  border-color: $accent-blue;

  svg {
    stroke: $accent-blue;
  }
}

.button .icon,
.scrollhint .icon,
.openvideo .icon {
  display: block;
  width: 30px;
  height: 30px;
  margin-left: 10px;
  margin-top: -15px;
  margin-bottom: -10px;

  svg {
    width: 100%;
    height: 100%;
  }

  @include breakpoint(medium) {
    width: 45px;
    height: 45px;
    margin-left: 15px;
  }
}

.overlay-play {
  width: 6vw;
  aspect-ratio: 1/1;
  .icon {
    width: 100%;
    height: 6vw;
  }
  svg > * {
    stroke: $accent-blue;
    stroke-width: 2px;
  }

  &:hover {
    svg path {
      fill: $accent-blue;
    }
  }
}

.bigbutton-ani {
  --bb-gradient: linear-gradient(
    90deg,
    rgb(255, 255, 255) -15%,
    rgb(18, 113, 255) -14%,
    rgb(18, 113, 255) -10%,
    rgb(255, 255, 255) -5%,
    rgb(0, 25, 67) 0
  );
  --stroke: 0em;

  display: inline-block;
  color: transparent;
  font-weight: 500;
  background: var(--bb-gradient);
  background-size: 100% 150%;
  background-clip: text;
  -webkit-text-stroke: var(--stroke) transparent;

  @include breakpoint(small only) {
    span.h1 {
      font-size: 6.5vw;
    }
  }
}

.button .icon.duplicate {
  position: absolute;
}

#menuDLink {
  display: none !important;
  margin-top: 0.25em;
  @include breakpoint(medium) {
    display: flex !important;
  }
}

.burger:hover,
.burger:focus {
  color: $white;
}

@keyframes play10 {
  0% {
    background-position: 0px 0px;
  }
  100% {
    background-position: -288px 0px;
  }
}
@keyframes rev10 {
  0% {
    background-position: -288px 0px;
  }
  100% {
    background-position: 0px 0px;
  }
}
@keyframes play1024 {
  0% {
    background-position: -288px 0px;
  }
  100% {
    background-position: -768px 0px;
  }
}
@keyframes rev1024 {
  0% {
    background-position: -768px 0px;
  }
  100% {
    background-position: -288px 0px;
  }
}

.burger {
  transform: translateY(3%);

  @include breakpoint(small only) {
    transform: translate(15%,3%);
  }
}

.burger .icon {
  background-image: url(../img/burger-icon.svg);
  animation-duration: 150ms;
  animation-timing-function: steps(9);
  animation-fill-mode: forwards;
  width: 32px;
  height: 32px;
  background-repeat: no-repeat;
  transition: all 0.15s linear;
  margin-top: 0;
  margin-bottom: 0;

  transform: scale(0.7) rotate(-45deg);
  @include breakpoint(medium) {
    transform: scale(1) rotate(-45deg);
  }
}
.burger .icon.step1 {
  animation-name: play10;
  transform: scale(0.7) rotate(0deg);
  @include breakpoint(medium) {
    transform: scale(1) rotate(0deg);
  }
}
.burger .icon.rev-step1 {
  animation-name: rev10;
}

.burger .icon.step2 {
  animation-name: rev1024;
  animation-duration: 0.3s;
  animation-timing-function: steps(15);
  transition: transform 0.3s linear;
}
.burger .icon.rev-step2 {
  transform: scale(0.7) rotate(-180deg);
  animation-name: play1024;
  animation-duration: 0.3s;
  animation-timing-function: steps(15);
  transition: transform 0.3s linear;

  @include breakpoint(medium) {
    transform: scale(1) rotate(-180deg);
  }
}

.burger .btnlabel {
  padding-top: 2px;
}

.scrollhint {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 50px;
  height: 50px;
  transform: translate(-50%, 50%);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  @include xy-fullgutters($grid-margin-gutters, margin, right, false);
  @include xy-smartgutters($spaced-vertical-gutters, margin, bottom, false, 1);

  .icon {
    margin: 0;
  }

  @include breakpoint(large down) {
    display: none;
  }
}

.openvideo {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 10;
  cursor: pointer;
  width: 250px;
  height: 50px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: $accent-blue;
  text-transform: uppercase;
  font-size: 14px;
  @include xy-fullgutters($grid-margin-gutters, margin, right, false);
  @include xy-smartgutters(
    $spaced-vertical-gutters,
    margin,
    bottom,
    false,
    0.5
  );

  @include breakpoint(large) {
    font-size: 16px;

    .text {
      opacity: 0;
    }
  }

  @include breakpoint(xlarge) {
    @include xy-smartgutters(
      $spaced-vertical-gutters,
      margin,
      bottom,
      false,
      1
    );
  }

  .icon {
    margin: 0 0 0 5px;
  }
  .icon svg path,
  .icon svg circle {
    stroke: $accent-blue;
  }

  @include breakpoint(medium down) {
    position: relative;
    margin: 0;
    width: auto;
    height: 30px;
    margin-top: 1rem;
  }
}

.close-button.medium,
.close-button {
  position: absolute !important;
  top: 0;
  right: 0;
  @include xy-fullgutters($grid-margin-gutters, margin, top right, false);
}

.close-button.medium,
.close-button {
  .icon {
    width: 20px;
    height: 20px;
    transition: all 0.4s ease;
  }
  &:hover .icon {
    transform: rotate(90deg);
  }
  &:hover .icon svg path {
    stroke: $accent-blue;
  }
}
