// breaks at large

#companyGrid {
  @include xy-smartgutters($grid-vertical-gutters, margin, top, false, 2);
  @include breakpoint(xlarge) {
    @include xy-smartgutters($grid-vertical-gutters, margin, top, false, 1.5);
  }

  .cell {
    position: relative;
    @include xy-smartgutters($grid-vertical-gutters, margin, bottom, false, 2);
    @include xy-smartgutters(
      $grid-padding-gutters,
      padding,
      left right,
      false,
      1
    );

    @include breakpoint(xlarge) {
      display: flex;
      justify-content: center;
      align-items: center;
      @include xy-smartgutters(
        $grid-padding-gutters,
        padding,
        left right,
        false,
        0.5
      );
      @include xy-smartgutters(
        $grid-vertical-gutters,
        margin,
        bottom,
        false,
        0.5
      );
    }

    @include breakpoint(xxlarge) {
      @include xy-smartgutters(
        $grid-padding-gutters,
        padding,
        left right,
        false,
        0.75
      );
    }

    a {
      justify-content: center;
      transition: all 0.4s ease;
    }

    a:hover {
      transform: scale(1.05);
      opacity: 0.8;
    }

    svg {
      width: 100%;
      height: auto;
      max-width: 350px;

      @include breakpoint(large) {
        max-width: 400px;
      }
    }
  }

  .gmbh {
    @include xy-smartgutters($grid-vertical-gutters, margin, bottom, false, 3);

    @include breakpoint(medium) {
      @include xy-smartgutters(
        $grid-vertical-gutters,
        margin,
        bottom,
        false,
        1.5
      );
    }

    @include breakpoint(xlarge) {
      margin-right: 25%;
      @include xy-smartgutters(
        $grid-vertical-gutters,
        margin,
        bottom,
        false,
        1.5
      );
    }

    svg {
      margin-bottom: 3%;
      max-width: 500px;

      @include breakpoint(xlarge) {
        max-width: 600px;
        width: 25vw;
      }
    }
  }

  .sport {
    @include breakpoint(xlarge) {
      @include xy-smartgutters($grid-vertical-gutters, margin, top, false, 0.5);
    }
    @include breakpoint(xxlarge) {
      @include xy-smartgutters(
        $grid-vertical-gutters,
        margin,
        top,
        false,
        0.75
      );
    }
  }

  .metal,
  .bridge,
  .sewer,
  .sport {
    @include breakpoint(500px) {
      width: 50%;
    }

    @include breakpoint(xlarge) {
      width: 33.3333%;
    }
  }

  @include breakpoint(xlarge) {
    .gmbh:after,
    .metal:after {
      content: "";
      display: block;
      position: absolute;
      height: 0;
      top: auto;
      bottom: 0;
      left: 50%;
      border-left: 1px solid $logo-blue;
      transform: translate(-50%, 100%);
      @include xy-smartgutters(
        $grid-vertical-gutters,
        padding,
        bottom,
        false,
        1.4
      );
    }

    .metal:after {
      transform: translate(-50%, 90%);
      @include xy-smartgutters(
        $grid-vertical-gutters,
        padding,
        bottom,
        false,
        1.2
      );
    }

    .bridge:after,
    .sewer:after {
      content: "";
      display: block;
      position: absolute;
      height: 0;
      top: 45%;
      right: 0;
      left: auto;
      width: 10%;
      border-top: 1px solid $logo-blue;
      transform: translateX(50%);
    }
    .sewer:after {
      left: 0;
      right: auto;
      transform: translateX(-50%);
    }
  }
  @include breakpoint(xxlarge) {
    .bridge:after,
    .sewer:after {
      width: 13%;
    }
    .gmbh:after,
    .metal:after {
      @include xy-smartgutters(
        $grid-vertical-gutters,
        padding,
        bottom,
        false,
        1.4
      );
    }
  }
  @include breakpoint(xxxlarge) {
    .bridge:after,
    .sewer:after {
      width: 22%;
    }
  }
}

/*
#companyGrid {
  @include breakpoint(large) {
    border: none;
  }

  .align-center-middle {
    height: 100%;
    transition: transform 0.3s ease;
    @include xy-smartgutters(
      $grid-vertical-gutters,
      padding,
      left right,
      false,
      1
    );

    &:hover {
      transform: scale(1.05);
    }

    @include breakpoint(large) {
      @include xy-smartgutters(
        $grid-vertical-gutters,
        padding,
        top bottom,
        false,
        0.5
      );
      @include xy-smartgutters(
        $grid-vertical-gutters,
        padding,
        left right,
        false,
        0.5
      );
    }
    @include breakpoint(xlarge) {
      @include xy-smartgutters(
        $grid-vertical-gutters,
        padding,
        top bottom,
        false,
        0.5
      );
    }
  }

  svg {
    fill: $logo-blue;
    width: 100%;
    height: auto;
    margin: 5% 0;
    transform: translateY(5%);

    @include breakpoint(large) {
      margin: 0;
    }
  }

  .cell:nth-child(2) svg {
    transform: translateY(-35%) scale(1.3);
  }

  .text-white & {
    .align-center-middle {
      border-color: $white;
    }
    svg {
      fill: $white;
    }
  }

  @include breakpoint(medium down) {
    .cell:nth-child(2) {
      width: 100% !important;
    }
  }

  @include breakpoint(large) {
    & .cell {
      position: relative;
    }
    & .cell:nth-child(2) {
      // @include xy-smartgutters(
      //   $grid-vertical-gutters,
      //   margin,
      //   bottom,
      //   false,
      //   1
      // );

      &:after {
        content: "";
        display: block;
        position: absolute;
        height: 0;
        left: 50%;
        border-left: 1px solid $logo-blue;
        border-right: 1px solid $logo-blue;
        @include xy-smartgutters(
          $grid-vertical-gutters,
          padding,
          bottom,
          false,
          0.6
        );
        @include xy-smartgutters(
          $grid-vertical-gutters,
          margin,
          top,
          true,
          0.3
        );
      }
    }
    & .cell:nth-child(4) {
      &:after {
        content: "";
        display: block;
        position: absolute;
        height: 0;
        right: 0;
        transform: translateX(50%);
        top: 50%;
        border-top: 1px solid $logo-blue;
        border-bottom: 1px solid $logo-blue;
        @include xy-smartgutters(
          $grid-vertical-gutters,
          padding,
          left,
          false,
          0.5
        );
      }
    }
    & .cell:nth-child(5) {
      &:after {
        content: "";
        display: block;
        position: absolute;
        height: 0;
        left: 50%;
        border-left: 1px solid $logo-blue;
        border-right: 1px solid $logo-blue;
        @include xy-smartgutters(
          $grid-vertical-gutters,
          padding,
          bottom,
          false,
          0.6
        );
        @include xy-smartgutters(
          $grid-vertical-gutters,
          margin,
          top,
          true,
          0.3
        );
      }
    }
    & .cell:nth-child(6) {
      &:after {
        content: "";
        display: block;
        position: absolute;
        height: 0;
        left: 0;
        transform: translateX(-50%);
        top: 50%;
        border-top: 1px solid $logo-blue;
        border-bottom: 1px solid $logo-blue;
        @include xy-smartgutters(
          $grid-vertical-gutters,
          padding,
          left,
          false,
          0.5
        );
      }
    }
    & .cell:nth-child(8) {
      // @include xy-smartgutters($grid-vertical-gutters, margin, top, false, 1);
    }
    & .cell:nth-child(3n + 1) div {
      // margin-right: -2px;
      // @include xy-smartgutters(
      //   $grid-vertical-gutters,
      //   margin,
      //   left right,
      //   false,
      //   0.5
      // );
    }
    & .cell:nth-child(3n + 3) div {
      margin-left: -2px;
      // @include xy-smartgutters(
      //   $grid-vertical-gutters,
      //   margin,
      //   left right,
      //   false,
      //   0.5
      // );
    }

    & .cell:nth-child(2) div {
      margin-top: 2px;
    }
    & .cell:nth-child(8) div {
      margin-top: -2px;
      // @include xy-smartgutters(
      //   $grid-vertical-gutters,
      //   margin,
      //   left right,
      //   false,
      //   0.5
      // );
    }

    & .cell:nth-child(5) div {
      // @include xy-smartgutters(
      //   $grid-vertical-gutters,
      //   margin,
      //   left right,
      //   false,
      //   0.5
      // );
    }
  }
}
*/
