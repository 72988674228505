.branchaccordion {
  a.accordion-title.grid-x {
    display: flex;
    transform: none;
    position: relative;
    @include xy-smartgutters(
      $grid-vertical-gutters,
      padding,
      top bottom,
      false,
      1
    );
  }
  a.grid-x,
  .accordion-content.grid-x {
    @include xy-gutters($grid-margin-gutters, marign, right left, true);
  }

  li.accordion-item {
    border-top-width: 1px;
    border-top-style: solid;
    border-color: $logo-blue;
  }

  .accordion-title > .cell:first-child {
    height: fit-content;
    @include xy-fullgutters($grid-margin-gutters, padding, right, false);
  }

  .accordion-title > * {
    display: block;
  }
  .accordion-title > .h1 {
    margin-top: -0.02em;
  }
  .accordion-title > .icon {
    @include xy-gutters($grid-margin-gutters, margin, right, false);
    @include xy-smartgutters(
      $grid-vertical-gutters,
      padding,
      top bottom,
      false,
      1
    );
    position: absolute;
    right: 0;
    top: -2px;
    width: 30px;
    height: 30px;
    @include breakpoint(medium) {
      width: 45px;
      height: 45px;
      top: 0px;
    }
  }
  .accordion-content {
    a.cell {
      @include xy-gutters($grid-margin-gutters, margin, right left, false);
    }
  }
  .accordion-content .grid-list li {
    align-items: flex-start;
  }

  svg.svglogo {
    width: 100%;
    max-width: 480px;
    height: unset;
    fill: $logo-blue;
  }
}
